import { ChainId, Token, WETH } from '@uniswap/sdk';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { QueryName } from 'common/models/graphql';
import { ChainId as chain } from '@ichidao/ichi-sdk/dist/src/crypto/networks';

export const API_GATEWAY_URL = process.env.API_GATEWAY_URL || process.env.GATSBY_API_GATEWAY_URL;
if (!API_GATEWAY_URL) {
  throw new Error('To continue set the API_GATEWAY_URL environment variable');
}

export const ENV = process.env.ENV || process.env.GATSBY_ENV || process.env.ENVIRONMENT || 'dev';
export const GIT_HASH = process.env.GIT_LONG_HASH || process.env.GATSBY_GIT_LONG_HASH || 'dev';
export const BUILD_TS = process.env.BUILD_TS || process.env.GATSBY_BUILD_TS;
export const APP_INSIGHTS_KEY = process.env.APP_INSIGHTS_KEY || process.env.GATSBY_APP_INSIGHTS_KEY;

type TokenMapping = {
  [chainId in ChainId]: Token;
};

type Farm = {
  chainId: ChainId;
  address: string;
  version: number;
};

type FarmMapping = {
  [chainId in ChainId]: Farm;
};

type QueryMapping = {
  [queryName in QueryName]: number;
};

export type Keeper = {
  providerUrl: string;
  tokenName: string;
  threshold: number;
  chainId: ChainId;
  chainName: string;
}
type KeeperMapping = {
  [chainId in ChainId]: Keeper;
};

export const OFFCHAIN_KEEPERS_ADDRESS = '0x8Bc0AEE1276DD867a92FAd7afFA7AF252569eCae';

export const KEEPERS:KeeperMapping = {
  [chain.Mainnet]: {
    providerUrl: process.env.GATSBY_NETWORK_URL || '',
    tokenName: 'ETH',
    threshold: 0.5,
    chainId: chain.Mainnet,
    chainName: 'Ethereum',
  },
  [chain.Arbitrum]: {
    providerUrl: process.env.GATSBY_ARBITRUM_RPC_HOSTS || '',
    tokenName: 'ETH',
    threshold: 0.05,
    chainId: chain.Arbitrum,
    chainName: 'Arbitrum',
  },
  [chain.Arthera]: {
    providerUrl: process.env.GATSBY_ARTHERA_RPC_HOSTS || '',
    tokenName: 'AA',
    threshold: 0.1,
    chainId: chain.Arthera,
    chainName: 'Arthera',
  },
  [chain.Berachain_bArtio]: {
    providerUrl: process.env.GATSBY_BARTIO_RPC_HOSTS || '',
    tokenName: 'BERA',
    threshold: 1,
    chainId: chain.Berachain_bArtio,
    chainName: 'Berachain bArtio',
  },
  [chain.Blast]: {
    providerUrl: process.env.GATSBY_BLAST_RPC_HOSTS || '',
    tokenName: 'ETH',
    threshold: 0.01,
    chainId: chain.Blast,
    chainName: 'Blast',
  },
  [chain.Bsc]: {
    providerUrl: process.env.GATSBY_BSC_RPC_HOSTS || '',
    tokenName: 'BNB',
    threshold: 1.5,
    chainId: chain.Bsc,
    chainName: 'Bsc',
  },
  [chain.Celo]: {
    providerUrl: process.env.GATSBY_CELO_RPC_HOSTS || '',
    tokenName: 'CELO',
    threshold: 14,
    chainId: chain.Celo,
    chainName: 'Celo',
  },
  [chain.Eon]: {
    providerUrl: process.env.GATSBY_EON_RPC_HOSTS || '',
    tokenName: 'ZEN',
    threshold: 2.1,
    chainId: chain.Eon,
    chainName: 'Eon',
  },
  [chain.Evmos]: {
    providerUrl: process.env.GATSBY_EVMOS_RPC_HOSTS || '',
    tokenName: 'EVMOS',
    threshold: 1,
    chainId: chain.Evmos,
    chainName: 'Evmos',
  },
  [chain.Fantom]: {
    providerUrl: process.env.GATSBY_FANTOM_RPC_HOSTS || '',
    tokenName: 'FTM',
    threshold: 2,
    chainId: chain.Fantom,
    chainName: 'Fantom',
  },
  [chain.Flare]: {
    providerUrl: process.env.GATSBY_FLARE_RPC_HOSTS || '',
    tokenName: 'FLR',
    threshold: 50,
    chainId: chain.Flare,
    chainName: 'Flare',
  },
  [chain.Hedera]: {
    providerUrl: process.env.GATSBY_HEDERA_RPC_HOSTS || '',
    tokenName: 'HBAR',
    threshold: 2000,
    chainId: chain.Hedera,
    chainName: 'Hedera',
  },
  [chain.Kava]: {
    providerUrl: process.env.GATSBY_KAVA_RPC_HOSTS || '',
    tokenName: 'KAVA',
    threshold: 5,
    chainId: chain.Kava,
    chainName: 'Kava',
  },
  [chain.Linea]: {
    providerUrl: process.env.GATSBY_LINEA_RPC_HOSTS || '',
    tokenName: 'ETH',
    threshold: 0.26,
    chainId: chain.Linea,
    chainName: 'Linea',
  },
  [chain.Mantle]: {
    providerUrl: process.env.GATSBY_MANTLE_RPC_HOSTS || '',
    tokenName: 'MNT',
    threshold: 500,
    chainId: chain.Mantle,
    chainName: 'Mantle',
  },
  [chain.Mode]: {
    providerUrl: process.env.GATSBY_MODE_RPC_HOSTS || '',
    tokenName: 'ETH',
    threshold: 0.001,
    chainId: chain.Mode,
    chainName: 'Mode',
  },
  [chain.opBNB]: {
    providerUrl: process.env.GATSBY_OPBNB_RPC_HOSTS || '',
    tokenName: 'BNB',
    threshold: 0.001,
    chainId: chain.opBNB,
    chainName: 'opBNB',
  },
  [chain.Real]: {
    providerUrl: process.env.GATSBY_REAL_RPC_HOSTS || '',
    tokenName: 'reETH',
    threshold: 0.005,
    chainId: chain.Real,
    chainName: 'Re.al',
  },
  [chain.Rootstock]: {
    providerUrl: process.env.GATSBY_ROOTSTOCK_RPC_HOSTS || '',
    tokenName: 'RBTC',
    threshold: 0.004,
    chainId: chain.Rootstock,
    chainName: 'Rootstock',
  },
  [chain.Scroll]: {
    providerUrl: process.env.GATSBY_SCROLL_RPC_HOSTS || '',
    tokenName: 'ETH',
    threshold: 0.02,
    chainId: chain.Scroll,
    chainName: 'Scroll',
  },
  [chain.Skale_Europa]: {
    providerUrl: process.env.GATSBY_SKALE_EUROPA_RPC_HOSTS || '',
    tokenName: 'sFUEL',
    threshold: 1,
    chainId: chain.Skale_Europa,
    chainName: 'Skale Europa',
  },
  [chain.Taiko]: {
    providerUrl: process.env.GATSBY_TAIKO_RPC_HOSTS || '',
    tokenName: 'ETH',
    threshold: 0.05,
    chainId: chain.Taiko,
    chainName: 'Taiko',
  },
  [chain.zkEVM]: {
    providerUrl: process.env.GATSBY_ZKEVM_RPC_HOSTS || '',
    tokenName: 'ETH',
    threshold: 0.8,
    chainId: chain.zkEVM,
    chainName: 'zkEVM',
  },
  [chain.zkSync]: {
    providerUrl: process.env.GATSBY_ZKSYNC_RPC_HOSTS || '',
    tokenName: 'ETH',
    threshold: 0.04,
    chainId: chain.zkSync,
    chainName: 'zkSync',
  },
}

export const monitoredChains = [
  chain.Mainnet,
  chain.Polygon,
  chain.Arbitrum,
  chain.Base,
  chain.Berachain_bArtio,
  chain.Blast,
  chain.Bsc,
  chain.Celo,
  chain.Eon,
  chain.Evmos,
  chain.Fantom,
  chain.Flare,
  chain.Fuse,
  chain.Hedera,
  chain.Kava,
  chain.Linea,
  chain.Mantle,
  chain.Mode,
  chain.opBNB,
  chain.Real,
  chain.Rootstock,
  chain.Scroll,
  chain.Skale_Europa,
  chain.Taiko,
  chain.Zircuit,
  chain.zkEVM,
  chain.zkSync,
]

export enum AmmName {
  Agni = 'Agni',
  Ascent = 'Ascent',
  Blueberry = 'Blueberry',
  Blueprint = 'Blueprint',
  Cleo = 'Cleo',
  Crust = 'Crust',
  Equalizer = 'Equalizer',
  Fenix = 'Fenix',
  Forge = 'Forge',
  Henjin = 'Henjin',
  Kim = 'Kim',
  Kinetix = 'Kinetix',
  Kodiak = 'Kodiak',
  Linehub = 'Linehub',
  Lynex = 'Lynex',
  Metavault = 'Metavault',
  Nile = 'Nile',
  Ocelex = 'Ocelex',
  PancakeSwap = 'PancakeSwap',
  Pearl = 'Pearl',
  QuickSwap = 'QuickSwap',
  Ramses = 'Ramses',
  Retro = 'Retro',
  SaucerSwap = 'SaucerSwap',
  SparkDex = 'SparkDex',
  SparkDexV1 = 'SparkDexV1',
  SpiritSwap = 'SpiritSwap',
  SushiSwap = 'SushiSwap',
  Thena = 'Thena',
  Thirdfy = 'Thirdfy',
  Thruster = 'Thruster',
  Ubeswap = 'Ubeswap',
  UniV3 = 'Uniswap V3',
  Voltage = 'Voltage',
}

export type MonitoredAmm = {
  name: string;
  buttonName: string;
  indicator: string;
  chain: chain;
}
export const MONITORED_AMMS = [
  { 
    chain: chain.Mainnet,
    amms: [
      {
        name: AmmName.UniV3,
        buttonName: 'Uniswap v3',
        indicator: '',
        chain: chain.Mainnet,
      },
      {
        name: AmmName.Blueberry,
        buttonName: 'Blueberry',
        indicator: 'blueberry)',
        chain: chain.Mainnet,
      },
      {
        name: AmmName.Blueprint,
        buttonName: 'Blueprint',
        indicator: 'blueprint)',
        chain: chain.Mainnet,
      },
      {
        name: AmmName.PancakeSwap,
        buttonName: 'Pancake',
        indicator: '(pancakeswap)',
        chain: chain.Mainnet,
      },
    ]
  },
  {
    chain: chain.Polygon,
    amms: [
      {
        name: AmmName.UniV3,
        buttonName: 'Uni v3',
        indicator: '(polygon)',
        chain: chain.Polygon,
      },
      {
        name: AmmName.QuickSwap,
        buttonName: AmmName.QuickSwap,
        indicator: `${AmmName.QuickSwap.toLocaleLowerCase()})`,
        chain: chain.Polygon,
      },
      {
        name: AmmName.Retro,
        buttonName: 'Retro',
        indicator: '(retro)',
        chain: chain.Polygon,
      },
    ]
  },
  {
    chain: chain.Arbitrum,
    amms: [
      {
        name: AmmName.UniV3,
        buttonName: 'Uni v3',
        indicator: '(arbitrum)',
        chain: chain.Arbitrum,
      },
      {
        name: AmmName.PancakeSwap,
        buttonName: 'PancakeSwap',
        indicator: 'pancakeswap)',
        chain: chain.Arbitrum,
      },
      {
        name: AmmName.Ramses,
        buttonName: 'Ramses',
        indicator: '(ramses)',
        chain: chain.Arbitrum,
      },
      {
        name: AmmName.SushiSwap,
        buttonName: 'Sushi',
        indicator: `(${AmmName.SushiSwap.toLocaleLowerCase()})`,
        chain: chain.Arbitrum,
      },
    ]
  },
  {
    chain: chain.Arthera,
    amms: [
      {
        name: AmmName.Thirdfy,
        buttonName: AmmName.Thirdfy,
        indicator: `(arthera-${AmmName.Thirdfy.toLowerCase()})`,
        chain: chain.Arthera,
      },
    ]
  },
  {
    chain: chain.Base,
    amms: [
      {
        name: AmmName.Equalizer,
        buttonName: AmmName.Equalizer,
        indicator: `(base-${AmmName.Equalizer.toLowerCase()})`,
        chain: chain.Base,
      },
      {
        name: AmmName.PancakeSwap,
        buttonName: AmmName.PancakeSwap,
        indicator: `(base-${AmmName.PancakeSwap.toLowerCase()})`,
        chain: chain.Base,
      },
      {
        name: AmmName.UniV3,
        buttonName: AmmName.UniV3,
        indicator: '(base-uniswap',
        chain: chain.Base,
      },
    ]
  },
  {
    chain: chain.Berachain_bArtio,
    amms: [
      {
        name: AmmName.Kodiak,
        buttonName: AmmName.Kodiak,
        indicator: `berachain_bartio-${AmmName.Kodiak.toLowerCase()})`,
        chain: chain.Berachain_bArtio,
      },
    ]
  },
  {
    chain: chain.Blast,
    amms: [
      {
        name: AmmName.Fenix,
        buttonName: AmmName.Fenix,
        indicator: `(blast-${AmmName.Fenix.toLowerCase()})`,
        chain: chain.Blast,
      },
      {
        name: AmmName.Thruster,
        buttonName: AmmName.Thruster,
        indicator: `(blast-${AmmName.Thruster.toLowerCase()})`,
        chain: chain.Blast,
      },
      {
        name: AmmName.UniV3,
        buttonName: AmmName.UniV3,
        indicator: `(blast-${AmmName.UniV3.toLowerCase()})`,
        chain: chain.Blast,
      },
    ]
  },
  {
    chain: chain.Bsc,
    amms: [
      {
        name: AmmName.UniV3,
        buttonName: 'Uni v3',
        indicator: '(bsc-uni v3)',
        chain: chain.Bsc,
      },
      {
        name: AmmName.PancakeSwap,
        buttonName: 'Pancake',
        indicator: '(bsc-pancakeswap)',
        chain: chain.Bsc,
      },
      {
        name: AmmName.Thena,
        buttonName: 'Thena',
        indicator: 'thena)',
        chain: chain.Bsc,
      },
    ]
  },
  {
    chain: chain.Celo,
    amms: [
      {
        name: AmmName.Ubeswap,
        buttonName: AmmName.Ubeswap,
        indicator: `(celo-${AmmName.Ubeswap.toLowerCase()})`,
        chain: chain.Celo,
      },
      {
        name: AmmName.UniV3,
        buttonName: 'Uni V3',
        indicator: '(celo-uniswapv3)',
        chain: chain.Celo,
      },
    ]
  },
  {
    chain: chain.Eon,
    amms: [
      {
        name: AmmName.Ascent,
        buttonName: 'Ascent',
        indicator: '(ascent)',
        chain: chain.Eon,
      },
    ]
  },
  {
    chain: chain.Evmos,
    amms: [
      {
        name: AmmName.Forge,
        buttonName: AmmName.Forge,
        indicator: `(evmos-${AmmName.Forge.toLowerCase()})`,
        chain: chain.Evmos,
      },
    ]
  },
  {
    chain: chain.Fantom,
    amms: [
      {
        name: AmmName.Equalizer,
        buttonName: 'Equalizer',
        indicator: 'equalizer)',
        chain: chain.Fantom,
      },
      {
        name: AmmName.SpiritSwap,
        buttonName: 'SpiritSwap',
        indicator: 'spiritswap)',
        chain: chain.Fantom,
      },
    ]
  },
  {
    chain: chain.Flare,
    amms: [
      {
        name: AmmName.SparkDex,
        buttonName: AmmName.SparkDex,
        indicator: '(flare-sparkdex)',
        chain: chain.Flare,
      },
      {
        name: AmmName.SparkDexV1,
        buttonName: AmmName.SparkDexV1,
        indicator: '(flare-sparkdexv1)',
        chain: chain.Flare,
      },
    ]
  },
  {
    chain: chain.Fuse,
    amms: [
      {
        name: AmmName.Voltage,
        buttonName: AmmName.Voltage,
        indicator: '(fuse-voltage)',
        chain: chain.Fuse,
      },
    ]
  },
  {
    chain: chain.Hedera,
    amms: [
      {
        name: AmmName.SaucerSwap,
        buttonName: 'SaucerSwap',
        indicator: 'saucerswap)',
        chain: chain.Hedera,
      },
    ]
  },
  {
    chain: chain.Kava,
    amms: [
      {
        name: AmmName.Kinetix,
        buttonName: AmmName.Kinetix,
        indicator: 'kava-kinetix)',
        chain: chain.Kava,
      },
    ]
  },
  {
    chain: chain.Linea,
    amms: [
      {
        name: AmmName.Linehub,
        buttonName: AmmName.Linehub,
        indicator: 'linea-linehub)',
        chain: chain.Linea,
      },
      {
        name: AmmName.Lynex,
        buttonName: AmmName.Lynex,
        indicator: 'lynex)',
        chain: chain.Linea,
      },
      {
        name: AmmName.Metavault,
        buttonName: AmmName.Metavault,
        indicator: 'linea-metavault)',
        chain: chain.Linea,
      },
      {
        name: AmmName.Nile,
        buttonName: AmmName.Nile,
        indicator: 'linea-nile)',
        chain: chain.Linea,
      },
      {
        name: AmmName.UniV3,
        buttonName: AmmName.UniV3,
        indicator: '(linea-u',
        chain: chain.Linea,
      },
    ]
  },
  {
    chain: chain.Mantle,
    amms: [
      {
        name: AmmName.Agni,
        buttonName: AmmName.Agni,
        indicator: '(mantle-agni)',
        chain: chain.Mantle,
      },
      {
        name: AmmName.Cleo,
        buttonName: AmmName.Cleo,
        indicator: 'cleo)',
        chain: chain.Mantle,
      },
      {
        name: AmmName.Crust,
        buttonName: AmmName.Crust,
        indicator: '(mantle-crust)',
        chain: chain.Mantle,
      },
    ]
  },
  {
    chain: chain.Mode,
    amms: [
      {
        name: AmmName.Kim,
        buttonName: AmmName.Kim,
        indicator: '(mode-kim)',
        chain: chain.Mode,
      },
    ]
  },
  {
    chain: chain.opBNB,
    amms: [
      {
        name: AmmName.Thena,
        buttonName: AmmName.Thena,
        indicator: '(opbnb-thena)',
        chain: chain.opBNB,
      },
    ]
  },
  {
    chain: chain.Real,
    amms: [
      {
        name: AmmName.Pearl,
        buttonName: AmmName.Pearl,
        indicator: '(re.al-pearl)',
        chain: chain.Real,
      },
    ]
  },
  {
    chain: chain.Rootstock,
    amms: [
      {
        name: AmmName.UniV3,
        buttonName: AmmName.UniV3,
        indicator: '(rootstock-uniswap',
        chain: chain.Rootstock,
      },
    ]
  },
  {
    chain: chain.Scroll,
    amms: [
      {
        name: AmmName.Metavault,
        buttonName: AmmName.Metavault,
        indicator: 'scroll-metavault)',
        chain: chain.Scroll,
      },
      {
        name: AmmName.UniV3,
        buttonName: AmmName.UniV3,
        indicator: '(scroll-uniswap',
        chain: chain.Scroll,
      },
    ]
  },
  {
    chain: chain.Skale_Europa,
    amms: [
      {
        name: AmmName.SushiSwap,
        buttonName: AmmName.SushiSwap,
        indicator: `europa-${AmmName.SushiSwap.toLowerCase()})`,
        chain: chain.Skale_Europa,
      },
    ]
  },
  {
    chain: chain.Taiko,
    amms: [
      {
        name: AmmName.Henjin,
        buttonName: AmmName.Henjin,
        indicator: AmmName.Henjin.toLowerCase(),
        chain: chain.Taiko,
      },
    ]
  },
  {
    chain: chain.Zircuit,
    amms: [
      {
        name: AmmName.Ocelex,
        buttonName: AmmName.Ocelex,
        indicator: AmmName.Ocelex.toLowerCase(),
        chain: chain.Zircuit,
      },
    ]
  },
  {
    chain: chain.zkSync,
    amms: [
      {
        name: AmmName.PancakeSwap,
        buttonName: 'Pancake',
        indicator: `zksync`,
        chain: chain.zkSync,
      },
    ]
  },
  {
    chain: chain.zkEVM,
    amms: [
      {
        name: AmmName.PancakeSwap,
        buttonName: 'Pancake',
        indicator: `zkevm-pancake`,
        chain: chain.zkEVM,
      },
      {
        name: AmmName.QuickSwap,
        buttonName: 'QuickSwap',
        indicator: `zkevm-quickswap`,
        chain: chain.zkEVM,
      },
    ]
  },
]

// -1 for Retro vaults on Polygon
export type TableFilters = ChainId | -1;

export const vaultsTableFilters = [
  chain.Mainnet,
  chain.Polygon,
  chain.Arbitrum,
  chain.Bsc,
  -1,
];

export const REFRESH_INTERVALS: QueryMapping = {
  [QueryName.listFarms]: 300000,
  [QueryName.listTreasuries]: 300000,
  [QueryName.getFarm]: 60000,
  [QueryName.getToken]: 60000,
  [QueryName.userInfo]: 60000,
  [QueryName.listBanners]: 300000,
  [QueryName.listMonitorVaults]: 300000,
  [QueryName.listMonitorStablePools]: 300000
};

export const ETH_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441', 18, 'ETH', 'ETH'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A', 18, 'ETH', 'ETH'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e', 18, 'ETH', 'ETH'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821', 18, 'ETH', 'ETH'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, '0x53C43764255c17BD724F74c4eF150724AC50a3ed', 18, 'ETH', 'ETH')
};

export const WETH_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: WETH[ChainId.MAINNET], // 0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2
  [ChainId.KOVAN]: WETH[ChainId.KOVAN],
  [ChainId.GÖRLI]: WETH[ChainId.GÖRLI],
  [ChainId.RINKEBY]: WETH[ChainId.RINKEBY],
  [ChainId.ROPSTEN]: WETH[ChainId.ROPSTEN]
};

export const USDC_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USDC'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x21632981cBf52eB788171e8dcB891C32F4834239', 6, 'test_usdc', 'USDC'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const ONE_ETH_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xEc0d77a58528a218cBf41Fa6E1585c8D7A085868', 9, 'oneETH', 'oneETH'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const ONE_ETH_OLD_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x6fcBBB527Fb2954BED2B224A5BB7c23C5AeEb6E1', 9, 'oneETH', 'oneETH'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x0506F4C4013D4E9046bb41fEb6380BC5E428bFB6', 9, 'oneETH', 'oneETH'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const ONE_VBTC_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x7BD198b9107496fD5cC3d7655AF52f43a8eDBc4C', 9, 'oneVBTC', 'oneVBTC'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const VBTC_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xe1406825186D63980fd6e2eC61888f7B91C4bAe4', 18, 'VBTC', 'VBTC'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const ONE_BTC_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xC88F47067dB2E25851317A2FDaE73a22c0777c37', 9, 'oneBTC', 'oneBTC'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const WBTC_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 18, 'WBTC', 'wBTC'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const ONE_LINK_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x18Cc17a1EeD37C02A77B0B96b7890C7730E2a2CF', 9, 'oneLINK', 'oneLINK'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const ICHI_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x903bEF1736CDdf2A537176cf3C64579C3867A881', 9, 'ICHI', 'ICHI'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x883cc74d965edb77311a3f9a93649e92e2aa14ba', 9, 'ICHI', 'ICHI'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const XICHI_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x70605a6457B0A8fBf1EEE896911895296eAB467E', 9, 'xICHI', 'xICHI'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x4a8a50cd18ccd55078630a4b17d16c892ff7f4db', 9, 'xICHI', 'xICHI'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// https://docs.chain.link/docs/link-token-contracts/
export const LINK_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x514910771af9ca656af840dff83e8264ecf986ca', 18, 'LINK', 'LINK'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const BANCOR_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C', 18, 'BNT', 'BNT'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// ---------------------------
// oneFIL
// ---------------------------

export const ONE_FIL_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x6d82017e55b1D24C53c7B33BbB770A86f2ca229D', 18, 'onefil', 'oneFIL'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x50633E780803b56a0d8606a3C674993080Ea98c1', 18, 'test_onefil', 'oneFIL'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const REN_FIL_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xD5147bc8e386d91Cc5DBE72099DAC6C9b99276F5', 18, 'renFIL', 'renFIL'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x3CB15c7048e7CfAcFBc8eFe9362fAC5e60012BD1', 18, 'test_renfil', 'renFIL'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// ---------------------------
// one1INCH
// ---------------------------
export const ONE_1INCH_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x853bb55c1f469902f088a629db8c8803a9be3857', 18, 'one1inch', 'one1INCH'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const ONEINCH_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x111111111117dC0aa78b770fA6A738034120C302', 18, '1INCH', '1INCH'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// ---------------------------
// oneFUSE
// ---------------------------
export const ONE_FUSE_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xbbce03b2e7f53cadca93251ca4c928af01db6404', 18, 'onefuse', 'oneFUSE'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const FUSE_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x970B9bB2C0444F5E81e9d0eFb84C8ccdcdcAf84d', 18, 'fuse', 'FUSE'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// ---------------------------
// oneMPH
// ---------------------------
export const ONE_MPH_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xBE3F88E18BE3944FdDa830695228ADBB82fA125F', 18, 'onemph', 'oneMPH'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const MPH_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x8888801aF4d980682e47f1A9036e589479e835C5', 18, 'mph', 'MPH'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// ---------------------------
// onePERL
// ---------------------------
export const ONE_PERL_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xD9A24485e71B9148e0Fd51F0162072099DF0dB67', 18, 'oneperl', 'onePERL'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const PERL_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xeca82185adCE47f39c684352B0439f030f860318', 18, 'perl', 'PERL'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// ---------------------------
// oneUNI
// ---------------------------
export const ONE_UNI_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x8290d7a64f25e6b5002d98367e8367c1b532b534', 18, 'oneuni', 'oneUNI'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x4238C45783551be0D848BbAdA853cCa6b265322f', 18, 'test_oneuni', 'oneUNI'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const UNI_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', 18, 'UNI', 'UNI'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0xdF2661E2E6A35B482E3F105bDE628B5e1F68aB41', 18, 'UNI', 'UNI'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// ---------------------------
// oneDODO
// ---------------------------
export const ONE_DODO_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xcA37530E7c5968627BE470081d1C993eb1dEaf90', 18, 'onedodo', 'oneDODO'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const DODO_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd', 18, 'dodo', 'DODO'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// ---------------------------
// oneFOX
// ---------------------------
export const ONE_FOX_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x03352D267951E96c6F7235037C5DFD2AB1466232', 18, 'onefox', 'oneFOX'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const FOX_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xc770EEfAd204B5180dF6a14Ee197D99d808ee52d', 18, 'fox', 'FOX'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// ---------------------------
// oneWING
// ---------------------------
export const ONE_WING_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x5047fc5C9D7c49Ab22e390d13646a6A3a2476eff', 18, 'onewing', 'oneWING'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const WING_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xDb0f18081b505A7DE20B18ac41856BCB4Ba86A1a', 9, 'pwing', 'pWING'),
  [ChainId.KOVAN]: null as unknown as Token,
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// Primarily used in Kovan - One Token Instance
// https://kovan.etherscan.io/token/0x5bf9b9bb304672c3d006955abfc516e8b37693f9
export const OTI_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: null as unknown as Token,
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x5bf9b9bb304672c3d006955abfc516e8b37693f9', 18, 'oti', 'OTI'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

// OTI Collateral token6
export const TOKEN6_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: null as unknown as Token,
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0xb994c68b4ed03d8f0aa9cb1b1729fa9bbbaa75e7', 6, 'Token6', 'Token6'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};
// OTI Member token18
export const TOKEN18_TOKENS: TokenMapping = {
  [ChainId.MAINNET]: null as unknown as Token,
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x670d1c929e7d6b9f847c60a35750a440cb0f9308', 18, 'Token18', 'Token18'),
  [ChainId.GÖRLI]: null as unknown as Token,
  [ChainId.RINKEBY]: null as unknown as Token,
  [ChainId.ROPSTEN]: null as unknown as Token
};

export const FARMING_V1: FarmMapping = {
  [ChainId.MAINNET]: { chainId: ChainId.MAINNET, address: '0xcC50953A743B9CE382f423E37b07Efa6F9d9B000', version: 1 },
  [ChainId.KOVAN]: null as unknown as Farm,
  [ChainId.GÖRLI]: null as unknown as Farm,
  [ChainId.RINKEBY]: null as unknown as Farm,
  [ChainId.ROPSTEN]: null as unknown as Farm
};

export const FARMING_V2: FarmMapping = {
  [ChainId.MAINNET]: { chainId: ChainId.MAINNET, address: '0x275dFE03bc036257Cd0a713EE819Dbd4529739c8', version: 2 },
  [ChainId.KOVAN]: { chainId: ChainId.KOVAN, address: '0xCfF363294b876F27dF7aCe9584B243177bF618af', version: 2 },
  [ChainId.GÖRLI]: null as unknown as Farm,
  [ChainId.RINKEBY]: null as unknown as Farm,
  [ChainId.ROPSTEN]: null as unknown as Farm
};

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const NetworkContextName = 'NETWORK';

export const config = {
  infuraAPI: 'fd5271bc06ca4769a5dce319b595e005',
  infuraID: 'd4d75ab8621a4c66a166b0c0e6f58c8c'
};

export const tokenSrc = 'https://ichi-images.s3.amazonaws.com/tokens/';
export const sidebarSrc = 'https://ichi-images.s3.amazonaws.com/sidebar/';
export const chainSrc = 'https://ichi-images.s3.amazonaws.com/chains/chain_logo_[chainid].png';

export enum POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
  BOTTOM_BOTTOM = 'bottom-bottom',
  SMI = 'smi'
};
export const sidebarItems = [
  {
    label: 'Home',
    path: '/',
    icon: {
      light: {
        default: { publicURL: '../../assets/image/ichi/sidebar/light/icon_lb_home.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/light/icon_db_home.svg' }
      },
      dark: {
        default: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_lb_home.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_db_home.svg' }
      }
    },
    position: POSITION.TOP
  },
  {
    label: 'Vaults',
    path: '/vaults',
    icon: {
      light: {
        default: { publicURL: '../../assets/image/ichi/sidebar/light/icon_lb_safe.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/light/icon_db_safe.svg' }
      },
      dark: {
        default: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_lb_safe.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_db_safe.svg' }
      }
    },
    offset: '0',
    position: POSITION.TOP
  },
  {
    label: 'Strategies',
    path: '/strategies',
    icon: {
      light: {
        default: { publicURL: '../../assets/image/ichi/sidebar/light/icon_lb_safe.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/light/icon_db_safe.svg' }
      },
      dark: {
        default: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_lb_safe.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_db_safe.svg' }
      }
    },
    offset: '0',
    position: POSITION.TOP
  },
  {
    label: 'TVLs',
    path: '/tvls',
    icon: {
      light: {
        default: { publicURL: '../../assets/image/ichi/sidebar/light/icon_lb_safe.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/light/icon_db_safe.svg' }
      },
      dark: {
        default: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_lb_safe.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_db_safe.svg' }
      }
    },
    offset: '0',
    position: POSITION.TOP
  },
  {
    label: 'Pools',
    path: '/pools',
    icon: {
      light: {
        default: { publicURL: '../../assets/image/ichi/sidebar/light/icon_lb_farm.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/light/icon_db_farm.svg' }
      },
      dark: {
        default: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_lb_farm.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_db_farm.svg' }
      }
    },
    offset: '0',
    position: POSITION.TOP
  },
  {
    label: 'Treasuries',
    path: '/treasuries',
    icon: {
      light: {
        default: { publicURL: '../../assets/image/ichi/sidebar/light/icon_lb_treasury.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/light/icon_db_treasury.svg' }
      },
      dark: {
        default: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_lb_treasury.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_db_treasury.svg' }
      }
    },
    offset: '0',
    position: POSITION.TOP
  },
  {
    label: 'Boost IRR',
    path: '/supportirr',
    icon: {
      light: {
        default: { publicURL: '../../assets/image/ichi/sidebar/light/icon_lb_treasury.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/light/icon_db_treasury.svg' }
      },
      dark: {
        default: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_lb_treasury.svg' },
        selected: { publicURL: '../../assets/image/ichi/sidebar/dark/icon_db_treasury.svg' }
      }
    },
    offset: '0',
    position: POSITION.TOP
  }
];

export const supportIrrTable = {
  headers: [
    {
      name: 'Vault'
    },
    {
      name: '1%'
    },
    {
      name: '5%'
    },
    {
      name: '10%'
    },
    {
      name: '15%'
    },
    {
      name: '20%'
    },
    {
      name: '25%'
    },
    {
      name: '30%'
    },
    {
      name: '35%'
    },
    {
      name: '40%'
    },
    {
      name: '45%'
    },
    {
      name: '50%'
    },
  ]
};

export const strategiesTable = {
  headers: [
    {
      name: 'Vault Name',
      sortBy: 'vaultName'
    },
    {
      name: 'Paused'
    },
    {
      name: 'Keeper'
    },
    {
      name: 'LINK Balance',
      sortBy: 'chainLinkBalance'
    },
    {
      name: 'TVL',
      sortBy: 'tvl'
    },
  ]
};

